<template>
  <div>
    <v-card-text class="biip__col">
      A sua atividade no evento
      <v-btn icon small :loading="refreshLoading" @click="getActivity"
        ><v-icon>mdi-autorenew</v-icon>
      </v-btn>
    </v-card-text>

    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="items"
      class="biip__table"
      :items-per-page="5"
      height="290px"
    >
      <template v-slot:[`item.date`]="{ item }">
        {{ toLocaleDate(item.date) }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        {{ item.type == 1 ? "Entrada" : "Saída" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import InfoSharedDetailsCard from "./InfoSharedDetailsCard.vue";
const axios = require("axios").default;

export default {
  components: {
    InfoSharedDetailsCard,
  },

  props: {
    eventId: {
      type: Number,
      require: true,
    },
  },

  data: () => ({
    items: [],
    headers: [
      {
        text: "Data",
        align: "start",
        sortable: false,
        value: "date",
      },
      { text: "Ação", value: "action", sortable: false },
      {
        text: "Descrição",
        value: "description",
        align: "center",
        sortable: false,
      },
    ],
    refreshLoading: false,
  }),

  watch: {
    eventId() {
      this.getActivity();
    },
  },

  created() {
    this.getActivity();
  },

  methods: {
    toLocaleDate(data) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(data).toLocaleDateString("pt-pt", options);
    },

    getActivity() {
      this.refreshLoading = true;
      axios
        .get(`/events/my-movements/${this.eventId}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.items = data;
            const currentDate = new Date();

            let canAsk = this.items.some((item) => {
              let logDate = new Date(item.date);

              return (
                item.description.includes("actividade") &&
                (currentDate - logDate) / (1000 * 60 * 60) < 1.5 &&
                item.permission
              );
            });

            this.$store.commit("changeCanAsk", canAsk);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refreshLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__table {
    background-color: transparent;
  }

  &__card {
    background-color: transparent;
  }

  &__col {
    text-align: center;
  }
}
</style>