<template>
  <v-app class="main-content">
    <app-bar></app-bar>
    <router-view class="biip__scroll"/>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
export default {
  components: {
    AppBar,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat/Montserrat-VariableFont_wght.ttf) format("truetype");
}

$body-font-family: "Montserrat";
$title-font: "Montserrat";

html,
body {
  // position: fixed;
  // width: 100vw;
  // max-height: 100vh;
  // overflow-y: scroll;
}

.biip {
  &__scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }
}

body::-webkit-scrollbar {
  display: none;
}
.main-content {
  background: url(./assets/fundo.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;
  .title {
    // To pin point specific classes of some components
    font-family: $title-font, sans-serif !important;
  }
}

tbody {
  tr:hover {
    background-color: rgba(255, 255, 255, 0.85) !important;
    color: #bb2649;

    .mdi-eye {
      color: #bb2649;
    }
  }
}
</style>


