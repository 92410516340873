<template>
  <v-sheet height="90vh" class="text-center px-4" dark color="transparent">
    <v-img
      class="mt-6"
      height="400px"
      contain
      src="../assets/logoc_sombra.png"
    ></v-img>

    <v-card-actions class="justify-center">
      <v-btn
        class="pa-4"
        rounded
        x-large
        color="white"
        @click="$emit('subscribe')"
      >
        <span style="color: #bb2649">Inscrever-me</span>
      </v-btn>
    </v-card-actions>

    <v-btn small color="white" text @click="$store.commit('changePage', 2)">
      Como funciona</v-btn
    >
  </v-sheet>
</template>

<script>
export default {
  data: () => ({}),
};
</script>