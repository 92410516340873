import { render, staticRenderFns } from "./RotateCard.vue?vue&type=template&id=6388dba4&scoped=true&"
import script from "./RotateCard.vue?vue&type=script&lang=js&"
export * from "./RotateCard.vue?vue&type=script&lang=js&"
import style0 from "./RotateCard.vue?vue&type=style&index=0&id=6388dba4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6388dba4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard})
