export default {
  state: {
    drawer: false,
    locale: "en",
    eventIdCount: 1,
    installEvent: null,
    page: 0,
    canAsk: false,
  },

  getters: {
    getDrawer(state) {
      return state.drawer;
    },
    getLocale(state) {
      return state.locale;
    },
    runningInStandalone() {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        return true;
      }
      return false;
    },
    getPage(state) {
      return state.page;
    },
    getCanAsk(state) {
      return state.canAsk;
    },
  },

  mutations: {
    changeDrawer(state) {
      state.drawer = !state.drawer;
    },
    setLocale(state, locale) {
      state.locale = locale;
    },
    incrementCount(state) {
      state.eventIdCount++;
    },
    saveInstallEvent(state, event) {
      console.log(event);
      state.installEvent = event;
    },
    changePage(state, page) {
      state.page = page;
    },
    changeCanAsk(state, canAsk) {
      state.canAsk = canAsk;
    },
  },

  actions: {
    eventIdCount({ state, commit }) {
      commit("incrementCount");
      return state.eventIdCount;
    },
  },
};
