<template>
  <div>
    <v-card-text class="biip__col">
      Dados partilhados
      <v-btn icon small :loading="refreshLoading" @click="getSharedData"
        ><v-icon>mdi-autorenew</v-icon>
      </v-btn>
    </v-card-text>

    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="sharedByMeList"
      class="biip__table"
      height="290px"
    >
      <template v-slot:[`item.data`]="{ item }">
        {{ infosSharingToString(item.infos_sharing) }}
      </template>

      <template v-slot:[`item.shares`]="{ item }">
        {{ item.infos_sharing.length }}
      </template>

      <template v-slot:[`item.views`]="{ item }">
        {{ getTotalViews(item.infos_sharing) }}
      </template>

      <template v-slot:[`item.duplications`]="{ item }">
        {{ getTotalDuplications(item.infos_sharing) }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-dialog :value="selectedShare == item" persistent>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small @click="showItem(item)">
              mdi-plus
            </v-icon>
          </template>
          <InfoSharedDetailsCard
            v-if="selectedShare && item.id == selectedShare.id"
            :infos="selectedShare.infos_sharing"
            @deleteInfo="deleteInfo"
            @close="selectedShare = null"
          />
        </v-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import InfoSharedDetailsCard from "./InfoSharedDetailsCard.vue";
const axios = require("axios").default;

export default {
  components: {
    InfoSharedDetailsCard,
  },

  props: {
    eventId: {
      type: Number,
      require: true,
    },

    updateFlag: {
      type: Number,
    },
  },

  data: () => ({
    sharedByMeList: [],
    headers: [
      {
        text: "Dado/Documento",
        align: "start",
        sortable: false,
        value: "data",
      },
      { text: "Partilhas", value: "shares", align: "center", sortable: false },
      {
        text: "Visualizações",
        value: "views",
        align: "center",
        sortable: false,
      },
      {
        text: "Cópias/Exportações",
        value: "duplications",
        align: "center",
        sortable: false,
      },
      { text: "Opções", value: "actions", align: "center", sortable: false },
    ],
    refreshLoading: false,
    selectedShare: null,
    infoNames: {
      name: "nome",
      email: "email",
      address: "morada",
      taxIdNumber: "número de contribuinte",
      phoneNumber: "número de telemóvel",
      iban: "IBAN",
      birthday: "data de nascimento",
      id: "cartão de cidadão",
      "drive-license": "carta de condução",
      "student-id": "cartão de estudante",
      cv: "curriculum vitae",
      "address-proof": "comprovativo de morada",
    },
  }),

  watch: {
    eventId() {
      this.getSharedData();
    },

    updateFlag() {
      this.getSharedData();
    },
  },

  created() {
    this.getSharedData();
  },

  methods: {
    deleteInfo(id) {
      this.selectedShare.infos_sharing =
        this.selectedShare.infos_sharing.filter((item) => item.id != id);

      this.sharedByMeList = this.sharedByMeList.filter(
        (item) => item.infos_sharing.length > 0
      );

      if (this.selectedShare.infos_sharing.length == 0) {
        this.selectedShare = null;
      }

      this.$emit("deleteInfo");
    },

    infosSharingToString(item) {
      return new RegExp("other:+").test(item[0].info_name)
        ? item[0].info_name.substring(6)
        : this.infoNames[item[0].info_name];
    },

    getTotalViews(sharing_list) {
      let views = sharing_list.reduce((total, item) => {
        return item.data_processing
          .filter((process) => process.type == "view")
          .concat(total);
      }, []);

      views = views.reduce((total, item) => {
        if (!total.includes(item.id)) {
          total.push(item.id);
        }
        return total;
      }, []);

      return views.length;
    },

    getTotalDuplications(sharing_list) {
      let copies = sharing_list.reduce((total, item) => {
        return item.data_processing
          .filter(
            (process) => process.type == "copy" || process.type == "download"
          )
          .concat(total);
      }, []);

      copies = copies.reduce((total, item) => {
        if (!total.includes(item.id)) {
          total.push(item.id);
        }
        return total;
      }, []);

      return copies.length;
    },

    groupByData(list) {
      return list.reduce((acc, share) => {
        let index = acc.findIndex((item) => item.info_name == share.info_name);

        if (index == -1) {
          let result = {
            info_name: share.info_name,
            infos_sharing: [share],
          };
          acc.push(result);
          return acc;
        } else {
          acc[index].infos_sharing.push(share);
          return acc;
        }
      }, []);
    },

    getSharedData() {
      this.refreshLoading = true;
      axios
        .get(`/info-sharing/shared-by-me/${this.eventId}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.sharedByMeList = this.groupByData(data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refreshLoading = false;
        });
    },

    showItem(item) {
      this.selectedShare = item;
    },
  },
};
</script>



<style lang="scss" scoped>
.biip {
  &__table {
    background-color: transparent;
  }

  &__card {
    background-color: transparent;
  }

  &__col {
    text-align: center;
  }
}
</style>