<template>
  <v-card
    rounded="lg"
    elevation="0"
    class="pa-2 my-4"
    color="rgba(187, 38, 73, 0.6)"
  >
    <p style="font-size: 36px" class="text-center"></p>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-img
          height="30px"
          src="../assets/visitastecnicas.png"
          contain
        ></v-img>
      </v-col>
      <v-col cols="6" md="3">
        <CompanyCard
          imageSrc="./passbook/1sem.png"
          participatedImageSrc="./passbook/1com.png"
          :participated="checkList.adidas"
          title="Visita técnica - Adidas"
        />
      </v-col>
      <v-col cols="6" md="3">
        <CompanyCard
          imageSrc="./passbook/2sem.png"
          participatedImageSrc="./passbook/2com.png"
          :participated="checkList.superbock"
          title="Visita técnica - Superbock"
        />
      </v-col>
    </v-row>

    <v-row class="mt-4 justify-center" dense>
      <v-col cols="12">
        <v-img height="34px" src="../assets/palestras.png" contain></v-img>
      </v-col>

      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/3sem.png"
          participatedImageSrc="./passbook/3com.png"
          :participated="checkList.liderança"
          title="Liderança Consciente"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/4sem.png"
          participatedImageSrc="./passbook/4com.png"
          :participated="checkList.politica"
          title="Política pública para a retenção de jovens em Portugal"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/5sem.png"
          participatedImageSrc="./passbook/5com.png"
          :participated="checkList.portoapplicationcenter"
          title="Porto Application Center e as suas práticas ágeis"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/6sem.png"
          participatedImageSrc="./passbook/6com.png"
          :participated="checkList.literaciafinanceira"
          title="Literacia Financeira"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/7sem.png"
          participatedImageSrc="./passbook/7com.png"
          :participated="checkList.industria"
          title="Indústria 4.0 em ação"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/8sem.png"
          participatedImageSrc="./passbook/8com.png"
          :participated="checkList.mundoatual"
          title="O mundo empresarial atual: Como me 'encaixo'?"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/9sem.png"
          participatedImageSrc="./passbook/9com.png"
          :participated="checkList.career"
          title="Step into your career"
        />
      </v-col>
      <v-col cols="6" md="3">
        <TalkCard
          imageSrc="./passbook/10sem.png"
          participatedImageSrc="./passbook/10com.png"
          :participated="checkList.papelengenharia"
          title="O papel de um Engenheiro para a construção de uma sociedade moderna"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TalkCard from "./TalkCard.vue";
import CompanyCard from "./CompanyCard.vue";
const axios = require("axios").default;

export default {
  components: {
    TalkCard,
    CompanyCard,
  },
  props: {
    eventId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      refreshLoading: false,
      activities: [],
    };
  },

  created() {
    this.getActivities();
  },

  computed: {
    checkList() {
      let checkList = {
        adidas: false,
        superbock: false,
        liderança: false,
        politica: false,
        portoapplicationcenter: false,
        literaciafinanceira: false,
        industria: false,
        mundoatual: false,
        career: false,
        papelengenharia: false,
      };

      this.activities.map((el) => {
        let aux = el.description.split("#");
        if (aux[1]) {
          checkList[aux[1]] = true;
        }
      });

      return checkList;
    },
  },

  methods: {
    getActivities() {
      this.refreshLoading = true;
      axios
        .get(`/events/my-participied-activities/${this.eventId}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.activities = data;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.refreshLoading = false;
        });
    },
  },
};
</script>