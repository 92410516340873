<template>
  <v-card class="text-start" light flat>
    <v-card flat style="height: 85vh; width: 95vw" class="biip__scroll" light>
      <v-window v-model="window" touchless reverse>
        <v-window-item>
          <v-card-title>Selecione os dados que pretende partilhar</v-card-title>
          <v-card-subtitle
            >Partilhe somente o mínimo necessário</v-card-subtitle
          >

          <v-list>
            <v-list-group
              :disabled="loadingInfos"
              :value="false"
              prepend-icon="mdi-information-outline"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  Informações
                  <span
                    v-if="numInfosSelected == 0"
                    style="color: grey; font-size: 14px"
                    >(nenhuma selecionada)</span
                  >
                  <span
                    v-if="numInfosSelected == 1"
                    style="color: grey; font-size: 14px"
                    >(1 selecionada)</span
                  >
                  <span
                    v-if="numInfosSelected > 1"
                    style="color: grey; font-size: 14px"
                    >({{ numInfosSelected }} selecionadas)</span
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(info, i) in $store.getters.getUserInfos"
                :key="i"
                class="ml-5"
              >
                <v-list-item-icon>
                  <v-checkbox
                    v-model="checkboxInfos[i]"
                    color="green darken-2"
                    off-icon="mdi-circle-outline"
                    on-icon="mdi-check-circle"
                    style="margin: 0px"
                    hide-details
                  ></v-checkbox>
                </v-list-item-icon>

                <v-list-item-title>
                  {{
                    new RegExp("other:+").test(info.info.name)
                      ? info.info.name
                          .substring(6)
                          .replace(/^\w/, (c) => c.toUpperCase())
                      : infoNames[info.info.name].replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-progress-linear
              v-if="loadingInfos"
              indeterminate
              color="primary"
            ></v-progress-linear>

            <v-list-group
              :value="false"
              color="primary"
              prepend-icon="mdi-file-document-outline"
              :disabled="loadingDocuments"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  Documentos
                  <span
                    v-if="numDocsSelected == 0"
                    style="color: grey; font-size: 14px"
                    >(nenhum selecionado)</span
                  >
                  <span
                    v-if="numDocsSelected == 1"
                    style="color: grey; font-size: 14px"
                    >(1 selecionado)</span
                  >
                  <span
                    v-if="numDocsSelected > 1"
                    style="color: grey; font-size: 14px"
                    >({{ numDocsSelected }} selecionados)</span
                  >
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(doc, i) in $store.getters.getUserDocs"
                class="ml-5"
                :key="i"
              >
                <v-list-item-icon>
                  <v-checkbox
                    v-model="checkboxDocs[i]"
                    color="green darken-2"
                    off-icon="mdi-circle-outline"
                    on-icon="mdi-check-circle"
                    style="margin: 0px"
                    hide-details
                  ></v-checkbox>
                </v-list-item-icon>

                <v-list-item-title>
                  {{
                    new RegExp("other:+").test(doc.document.type)
                      ? doc.document.type
                          .substring(6)
                          .replace(/^\w/, (c) => c.toUpperCase())
                      : infoNames[doc.document.type].replace(/^\w/, (c) =>
                          c.toUpperCase()
                        )
                  }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group>

            <v-progress-linear
              v-if="loadingDocuments"
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-list>

          <v-textarea
            v-model="ownersNote"
            style="margin: 0px 16px"
            label="Mensagem"
            outlined
            :counter="counter"
            :rules="rules"
          ></v-textarea>
        </v-window-item>

        <v-window-item>
          <v-card-title>Leia o código QR do destinatário</v-card-title>

          <v-card flat style="height: 70vh; width: 95vw">
            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
              <div v-show="loadingRequest" class="biip__scan-confirmation">
                <v-row dense>
                  <v-col align-self="center" class="biip__col" cols="12">
                    <v-progress-circular
                      style="margin-top: auto; margin-bottom: auto"
                      size="40"
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                    <v-card-text>A comunicar dados</v-card-text>
                  </v-col>
                </v-row>
              </div>
            </qrcode-stream>
          </v-card>
        </v-window-item>

        <v-window-item>
          <v-card-title v-if="successFlag">Partilha bem sucedida</v-card-title>
          <v-card-subtitle v-if="successFlag"
            >As informações já podem ser consultadas</v-card-subtitle
          >

          <v-card-title v-if="errorFlag"
            >Ocorreu um erro na partilha</v-card-title
          >
          <v-card-subtitle v-if="errorFlag && errorType == 'didntcheckin'"
            >Partipante não deu entrada no evento</v-card-subtitle
          >
          <v-card-subtitle v-if="errorFlag && errorType == 'notfound'"
            >Cliente não encontrado</v-card-subtitle
          >
          <v-card-subtitle v-if="errorFlag && errorType == 'general'"
            >Verfique os dados e volte a tentar</v-card-subtitle
          >

          <v-card flat style="height: 70vh; width: 95vw">
            <v-row
              class="justify-space-around"
              align="center"
              style="height: 100%"
            >
              <v-icon v-if="successFlag" color="success" size="120"
                >mdi-check-circle</v-icon
              >
              <v-icon v-if="errorFlag" color="error" size="120"
                >mdi-close-circle</v-icon
              >
            </v-row>
          </v-card>
        </v-window-item>
      </v-window>
    </v-card>

    <v-card-actions>
      <v-row dense justify="space-between" style="padding: 0px 4px">
        <v-btn v-if="window == 0" small text @click="$emit('close')"
          >cancelar</v-btn
        >
        <v-btn v-else small text @click="window--"
          ><v-icon>mdi-chevron-left</v-icon>voltar</v-btn
        >

        <v-btn
          v-if="window == 0"
          :disabled="
            (numDocsSelected == 0 && numInfosSelected == 0) ||
            ownersNote.length > counter
          "
          color="primary"
          depressed
          @click="window = 1"
          >partilhar</v-btn
        >

        <v-btn
          v-if="window == 2"
          color="primary"
          depressed
          @click="$emit('close')"
          >fechar</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
const axios = require("axios").default;

export default {
  components: {
    QrcodeStream,
  },

  props: {
    eventId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      loadingInfos: false,
      loadingDocuments: false,
      checkboxInfos: [],
      checkboxDocs: [],
      window: 0,
      camera: "auto",
      loadingRequest: false,
      ownersNote: "",
      counter: 50,
      rules: [(v) => v.length <= 50 || "Max 50 characters"],
      successFlag: false,
      errorFlag: false,
      errorType: "",
      infoNames: {
        name: "nome",
        email: "email",
        address: "morada",
        taxIdNumber: "número de contribuinte",
        phoneNumber: "número de telemóvel",
        iban: "IBAN",
        birthday: "data de nascimento",
        id: "cartão de cidadão",
        "drive-license": "carta de condução",
        "student-id": "cartão de estudante",
        cv: "curriculum vitae",
        "address-proof": "comprovativo de morada",
      },
    };
  },

  created() {
    this.checkboxInfos = this.$store.getters.getUserInfos.map(() => false);
    this.checkboxDocs = this.$store.getters.getUserDocs.map(() => false);

    // this.getUserDocs();
    // this.getUserInfos();
  },

  computed: {
    numInfosSelected() {
      return this.checkboxInfos.filter((info) => info).length;
    },

    numDocsSelected() {
      return this.checkboxDocs.filter((info) => info).length;
    },

    docsPayload() {
      return this.checkboxDocs.reduce((total, item, index) => {
        if (item) {
          return total.concat({
            id: this.$store.getters.getUserDocs[index].document.id,
            type: "doc",
          });
        } else {
          return total;
        }
      }, []);
    },

    infosPayload() {
      return this.checkboxInfos.reduce((total, item, index) => {
        if (item) {
          return total.concat({
            id: this.$store.getters.getUserInfos[index].info.id,
            type: "text",
          });
        } else {
          return total;
        }
      }, []);
    },
  },

  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingRequest = this.camera === "off";
      }
    },

    async onDecode(content) {
      this.pause();
      this.createInfoSharing(content.trim());
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    getUserDocs() {
      axios
        .get(`/docs/mydocs`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            this.$store.commit("setUserDocs", data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.checkboxDocs = this.$store.getters.getUserDocs.map(() => false);
          this.loadingDocuments = false;
        });
    },

    getUserInfos() {
      axios
        .get(`/client_info/myinfos`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            this.$store.commit("setUserInfo", data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.checkboxInfos = this.$store.getters.getUserInfos.map(
            () => false
          );
          this.loadingInfos = false;
        });
    },

    createInfoSharing(shareCode) {
      this.errorFlag = false;
      this.successFlag = false;

      let payload = {
        info_sharing: {
          event_id: this.eventId,
          share_code: shareCode,
          owners_note: this.ownersNote,
          infos_list: this.infosPayload.concat(this.docsPayload),
        },
      };

      axios
        .post(`/info-sharing/`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status }) => {
          if (status === 200) {
            this.window = 2;
            this.successFlag = true;
            this.$emit("shared");
          }
        })
        .catch((err) => {
          this.window = 2;
          this.errorType = "general";
          if (err.response.status === 404) {
            this.errorType = "notfound";
          }
          if (
            err.response.status === 422 &&
            err.response.data.error == "participant did not check in"
          ) {
            this.errorType = "didntcheckin";
          }

          this.errorFlag = true;
        })
        .finally(() => {
          this.unpause();
          this.loadingRequest = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }

  &__scan-confirmation {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);

    display: flex;
    justify-content: center;
  }

  &__col {
    text-align: center;
  }
}
</style>