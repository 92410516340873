<template>
  <v-container class="biip__avatar-container px-2">
    <div class="user">
      <v-img :src="image_name" class="profile-img">
        <v-expand-transition>
          <v-alert
            class="biip__img-error"
            v-if="invalidTypeFlag"
            dense
            color="error"
            dismissible
          >
            <v-card-text class="biip__img-error--text">
              Tipo de ficheiro inválido
            </v-card-text>
          </v-alert>
        </v-expand-transition>
      </v-img>

      <v-icon
        v-if="!new_image_flag"
        class="icon primary"
        dark
        @click="$refs.ImageInput.click()"
        >mdi-camera-plus</v-icon
      >

      <v-icon
        v-if="new_image_flag"
        dark
        class="icon-save primary"
        @click="saveImage"
        >mdi-content-save</v-icon
      >

      <v-icon
        v-if="new_image_flag"
        dark
        class="icon primary"
        @click="
          image_name = '';
          reset();
        "
        >mdi-close</v-icon
      >

      <input
        ref="ImageInput"
        type="file"
        class="biip__input--no-display"
        accept=".jpeg, .jpg, .png"
        @change="onFileSelect"
      />
    </div>

    <v-overlay v-model="dialog">
      <v-card outlined color="secondary">
        <VueCropper
          style="max-height: 80vh; max-width: 90vw"
          :viewMode="2"
          v-show="selectedFile"
          ref="cropper"
          :src="selectedFile"
          alt="Source Image"
          :aspectRatio="1"
        >
        </VueCropper>

        <v-card-actions class="justify-space-between">
          <v-btn
            small
            color="primary"
            text
            @click="
              clearCrop();
              dialog = false;
            "
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            depressed
            @click="
              crop();
              dialog = false;
            "
            >Crop</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script>
const permitedMimes = ["image/png", "image/jpeg", "image/pjpeg"];
const axios = require("axios").default;

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  components: { VueCropper },
  data() {
    return {
      mime_type: "",
      cropedImage: "",
      selectedFile: "",
      dialog: false,
      image_name: "",
      image_name_old: "",
      new_image_flag: false,
      invalidTypeFlag: false,
    };
  },
  created() {
    this.image_name_old = this.image_name = this.$store.state.user.user.image;
  },
  methods: {
    reset() {
      const input = this.$refs.ImageInput;
      input.type = "text";
      input.type = "file";
      this.image_name = this.image_name_old;
      this.new_image_flag = false;
    },
    clearCrop() {
      this.$refs.ImageInput.value = "";
    },
    crop() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.image_name = this.cropedImage;
      this.new_image_flag = true;
      this.$refs.ImageInput.value = "";
    },
    saveImage() {
      var payload = {
        client: {
          image_src: this.image_name,
        },
      };
      axios
        .put(`clients/${this.$store.state.user.user.client_id}`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status }) => {
          if (status === 200) {
            this.image_name_old = this.image_name;
            this.new_image_flag = false;
            this.$store.commit("setUserImage", this.image_name);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileSelect(e) {
      const file = e.target.files[0];
      this.mime_type = file.type;
      if (permitedMimes.includes(this.mime_type)) {
        if (typeof FileReader === "function") {
          this.dialog = true;
          const reader = new FileReader();
          reader.onload = (event) => {
            this.selectedFile = event.target.result;
            this.$refs.cropper.replace(this.selectedFile);
          };
          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      } else {
        this.invalidTypeFlag = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user {
  border-radius: 3px;
  border: 2px solid #bb2649;
  position: relative;
}

.profile-img {
  height: 100%;
  width: 100%;
}

.icon {
  position: absolute;
  top: 5px;
  right: 5px;
  background: #f2f5fa;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.icon-save {
  position: absolute;
  top: 5px;
  right: 40px;
  background: #f2f5fa;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}
.biip {
  &__img-error {
    position: absolute;
    bottom: 0px;
    width: 100%;
    margin: 0px;
    border-radius: 0px 0px 3px 3px;
    &--text {
      padding: 4px;
    }
  }
  &__avatar-container {
    padding: 0px;
  }
  &__input {
    &--no-display {
      display: none;
    }
  }
}
</style>
