<template>
  <v-card class="biip__scroll" light>
    <v-card-title
      >Participantes com quem partilhou esta informação</v-card-title
    >
    <v-card-subtitle style="color: grey"
      >Podes consultar os detalhes de cada partilha</v-card-subtitle
    >

    <v-list v-if="infos.length > 0">
      <v-list-group
        v-for="info in infos"
        :key="info.id"
        no-action
        color="primary"
        @click="showInfo(info)"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="font-size: 18px">
              {{ getParticipantName(info.receivers_id) }}
              <span style="color: grey; font-size: 14px"
                >({{ info.receivers_id }})</span
              >
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item style="padding-left: 20px">
          <v-row dense>
            <v-col cols="12">
              <p style="margin-bottom: 4px">
                <b>As suas notas:</b>
              </p>

              <v-text-field
                v-model="updatedNote"
                placeholder="(não inseriu nenhuma nota)"
                dense
                :readonly="editNoteId != info.id"
                :counter="counter"
                :rules="rules"
              >
                <template slot="append">
                  <v-btn
                    v-if="editNoteId != info.id"
                    icon
                    @click="editNoteId = info.id"
                  >
                    <v-icon>mdi-pencil-outline</v-icon></v-btn
                  >

                  <v-btn
                    v-if="editNoteId == info.id && !updateNoteLoading"
                    icon
                    @click="undoNote(info)"
                  >
                    <v-icon>mdi-arrow-u-left-top</v-icon></v-btn
                  >

                  <v-btn
                    v-if="editNoteId == info.id"
                    :disabled="updatedNote.length > counter"
                    :loading="updateNoteLoading"
                    icon
                    @click="saveNote(info)"
                  >
                    <v-icon>mdi-content-save-outline</v-icon></v-btn
                  >
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <p style="margin-bottom: 4px"><b>Processamento do dado:</b></p>

              <div
                v-if="
                  info.data_processing &&
                  info.data_processing.filter(
                    (el) => el.responsible_id == info.receivers_id
                  ).length > 0
                "
              >
                <v-simple-table
                  class="biip__scroll"
                  height="200px"
                  fixed-header
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Data</th>
                        <th class="text-left">Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in info.data_processing.filter(
                          (el) => el.responsible_id == info.receivers_id
                        )"
                        :key="item.name"
                      >
                        <td>{{ toLocaleDate(item.date) }}</td>

                        <td>{{ item.type }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <br />
              </div>

              <p v-else>Este dado não foi processado.</p>
            </v-col>

            <v-col cols="12">
              <p style="margin-bottom: 4px"><b>Data da partilha:</b></p>
              {{ toLocaleDate(info.shared_at) }}
            </v-col>

            <v-col class="mb-2" cols="12">
              <p style="margin-bottom: 4px"><b>Opções:</b></p>
              <v-btn depressed color="error" small @click="deleteShare(info)"
                >Eliminar Partilha</v-btn
              >
            </v-col>
          </v-row>
        </v-list-item>

        <v-divider></v-divider>
      </v-list-group>
    </v-list>

    <v-card-text v-else style="text-align: center">
      Esta informação não está a ser partilhado com ninguém.
    </v-card-text>

    <v-card-actions class="justify-space-between">
      <v-btn text @click="$emit('close')"
        ><v-icon>mdi-chevron-left</v-icon> Voltar</v-btn
      >
    </v-card-actions>

    <v-overlay :value="selectedImage">
      <image-zoom-card
        v-if="selectedImage"
        :image="selectedImage"
        @close="selectedImage = null"
      ></image-zoom-card>
    </v-overlay>

    <v-overlay v-if="selectedDocument">
      <web-viewer
        :base64="selectedDocument"
        @close="selectedDocument = null"
      ></web-viewer>
    </v-overlay>

    <v-overlay v-if="itemToDelete" max-width="500px">
      <v-card light>
        <v-card-title>
          Tem a certeza que pretende eliminar esta partilha?
        </v-card-title>

        <v-card-actions class="justify-space-between">
          <v-btn :disabled="deleteLoading" text @click="itemToDelete = null"
            >Cancelar</v-btn
          >
          <v-btn
            :loading="deleteLoading"
            color="error"
            depressed
            @click="deleteItemConfirm"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-card>
</template>

<script>
const ImageZoomCard = () => import("./ImageZoomCard.vue");
const WebViewer = () => import("./WebViewer.vue");
const axios = require("axios").default;

export default {
  components: {
    ImageZoomCard,
    WebViewer,
  },

  props: {
    infos: {
      type: Array,
      require: true,
    },
  },

  data() {
    return {
      editNoteId: "",
      updatedNote: "",
      infoSelectedId: "",
      counter: 50,
      rules: [(v) => v.length <= 50 || "Max 50 characters"],
      updateNoteLoading: false,
      infoValue: null,
      loadingInfo: false,
      selectedImage: "",
      selectedDocument: "",
      receivers_names: [],
      loadingNames: true,
      itemToDelete: null,
      deleteLoading: false,
    };
  },

  created() {
    this.getParticipantsNames();
  },

  methods: {
    deleteShare(info) {
      this.itemToDelete = info;
    },

    deleteItemConfirm() {
      this.deleteLoading = true;
      axios
        .delete(`/info-sharing/${this.itemToDelete.id}`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status }) => {
          if (status === 204) {
            this.$emit("deleteInfo", this.itemToDelete.id);
            this.itemToDelete = null;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.deleteLoading = false));
    },

    getParticipantName(receivers_id) {
      if (this.loadingNames) {
        return "(a carregar nome)";
      }
      let name = this.receivers_names.find(
        (el) => el && el.client_id == receivers_id
      );
      return name ? name.name : "(o participante não tem um nome definido)";
    },

    getParticipantsNames() {
      this.loadingNames = true;
      let ids = this.infos.map((info) => info.id);

      axios
        .get(`/info-sharing/get-receivers-names/`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
          params: { info_sharing_ids: ids },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.receivers_names = data.data;
          }
        })
        .finally(() => (this.loadingNames = false));
    },

    isPdfFile(base64) {
      if (base64) {
        var arr = base64.split(",");
        if (arr[0].match(/:(.*?);/)) {
          return arr[0].match(/:(.*?);/)[1] === "application/pdf";
        } else {
          false;
        }
      } else {
        false;
      }
    },

    showInfo(info) {
      this.updatedNote = info.owners_note ? info.owners_note : "";
      this.editNoteId = "";
    },

    undoNote(info) {
      this.updatedNote = info.owners_note ? info.owners_note : "";
      this.editNoteId = "";
    },

    saveNote(info) {
      this.updateNoteLoading = true;
      axios
        .put(
          `/info-sharing/owners-note/${info.id}`,
          { owners_note: this.updatedNote },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.token,
            },
          }
        )
        .then(({ status, data }) => {
          if (status == 200) {
            let index = this.infos.findIndex((el) => el.id == info.id);
            this.infos[index].owners_note = this.updatedNote;
            this.editNoteId = "";
            this.updatedNote = data.owners_note;
          }
        })
        .catch((err) => console.log(err))
        .finally(() => (this.updateNoteLoading = false));
    },

    toLocaleDate(data) {
      const options = {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(data).toLocaleDateString("pt-pt", options);
    },
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }
}
</style>