<template>
  <v-app-bar app color="secondary">
    <div class="d-flex align-center">
      <v-img
        class="shrink mr-2 jee_logo"
        contain
        src="../assets/logo-jee.png"
        transition="scale-transition"
        width="90px"
        @click="
          !$store.getters.userIsLogged ? $store.commit('changePage', 0) : null
        "
      />
    </div>

    <v-spacer></v-spacer>

    <v-btn
      v-if="!$store.getters.userIsLogged"
      outlined
      color="primary"
      small
      @click="$store.commit('changePage', 1)"
    >
      Login
    </v-btn>

    <v-btn v-else icon color="primary" @click="logout">
      <v-icon class="ml-2">mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
const axios = require("axios").default;
export default {
  methods: {
    logout() {
      axios
        .post(
          `users/logout`,
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.token,
            },
          }
        )
        .then()
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>

<style lang="scss" scoped>
.jee_logo:hover {
  cursor: pointer;
}
</style>