<template>
  <v-container>
    <v-row>
      <v-col
        class="biip_text-col"
        align-self="center"
        xs="12"
        sm="12"
        md="5"
        lg="5"
      >
        <v-card
          dark
          v-if="!recoverPassFlag"
          flat
          color="rgba(187, 38, 73, 0.6)"
          class="text-center"
        >
          <v-card-text class="pb-0" style="color: white; font-size: 28px">
            <b>Login</b>
          </v-card-text>
          <v-card-subtitle
            >Faça login para poder interagir com o evento</v-card-subtitle
          >
          <v-card-text>
            <v-alert
              dense
              dismissible
              close-icon="mdi-close"
              color="error"
              v-model="error"
              dark
            >
              Email ou palavra-chave estão errados
            </v-alert>
            <v-alert
              dense
              dismissible
              close-icon="mdi-close"
              color="error"
              v-model="emailConfirmationFlag"
              dark
            >
              Email não confirmado
              <a
                style="color: white; text-decoration: underline"
                @click="resendEmail"
              >
                Reenviar email
              </a>
            </v-alert>
            <v-alert
              dense
              dismissible
              close-icon="mdi-close"
              color="success"
              v-model="alertSuccessResend"
              dark
            >
              Email de confirmação reenviado
            </v-alert>
            <v-alert
              dense
              dismissible
              close-icon="mdi-close"
              color="error"
              v-model="alertInsuccessResend"
              dark
            >
              Erro ao reenviar email. Por favor confirme o email.
              <a @click="resendEmail"> Reenviar email</a>
            </v-alert>
            <br />
            <v-form ref="form" v-model="form" lazy-validation>
              <v-text-field
                v-model="email"
                required
                :rules="emailRules"
                outlined
                flat
                color="white"
                label="Email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :type="showPass ? 'text' : 'password'"
                required
                :rules="passwordRules"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                outlined
                flat
                color="white"
                label="Palavra-chave"
                v-on:keyup.enter="login()"
              ></v-text-field>
            </v-form>
            <v-btn
              color="white"
              outlined
              :disabled="!form"
              :loading="loginLoading"
              @click="login"
            >
              Entrar
              <v-icon color="gold">mdi-chevron-right</v-icon>
            </v-btn>
            <br /><br />
            <h4 style="color: primary">
              Esqueceu-se da palavra-chave?
              <a class="biip_signup-link" @click="recoverPassFlag = true">
                Recupere
              </a>
            </h4>
            <h4 style="color: primary">
              Ainda não tem conta?
              <a class="biip_signup-link" @click="$emit('toSignup')"
                >Registe-se</a
              >
            </h4>
          </v-card-text>
        </v-card>

        <v-card
          class="text-center"
          v-else
          dark
          color="rgba(187, 38, 73, 0.6)"
          flat
        >
          <v-card-text class="pb-0" style="color: white; font-size: 28px"
            ><b>Recuperar palavra-passe</b></v-card-text
          >
          <v-card-subtitle
            >Insira o seu email e siga as instruções que vai receber na sua
            caixa de correio.</v-card-subtitle
          >
          <v-card-text>
            <v-alert
              v-model="successFlag"
              dense
              dismissible
              close-icon="mdi-close"
              dark
              color="success"
            >
              Email enviado com sucesso, consulte a sua caixa de correio.
            </v-alert>
            <v-alert
              v-model="insuccessFlag"
              dense
              dismissible
              close-icon="mdi-close"
              dark
              color="error"
            >
              Verifique o seu email e tente novamente.
            </v-alert>

            <v-text-field
              v-if="!successFlag"
              dense
              v-model="email"
              outlined
              color="white"
              label="Email"
              v-on:keyup.enter="submitRequest"
            ></v-text-field>

            <v-card-actions class="justify-space-between pa-0">
              <v-btn small text @click="recoverPassFlag = false">voltar</v-btn>
              <v-btn
                v-if="!successFlag"
                :disabled="!email"
                :loading="load"
                @click="submitRequest"
                outlined
                color="white"
                >Enviar <v-icon color="gold">mdi-chevron-right</v-icon></v-btn
              >
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;

export default {
  components: {},

  name: "LoginComponent",
  data: () => ({
    flagResend: true,
    alertInsuccessResend: false,
    alertSuccessResend: false,
    emailConfirmationFlag: false,
    showPass: false,
    form: null,
    email: "",
    password: "",
    error: false,
    successFlag: false,
    insuccessFlag: false,
    load: false,
    recoverPassFlag: false,
    loginLoading: false,
  }),
  methods: {
    resendEmail() {
      if (this.email != "") {
        let payload = {
          email: this.email,
        };
        axios
          .put(`/users/confirm-account/resend`, payload)
          .then(({ status }) => {
            if (status === 204) {
              this.emailConfirmationFlag = false;
              this.alertSuccessResend = true;
              this.alertInsuccessResend = false;
            }
          })
          .catch(() => {
            this.emailConfirmationFlag = false;
            this.alertInsuccessResend = true;
          });
      } else {
        this.flagResend = false;
      }
    },

    submitRequest() {
      this.load = true;
      let payload = {
        password_request: {
          email: this.email,
        },
      };
      axios
        .post(`/users/recover-password`, payload)
        .then(({ status }) => {
          if (status === 204) {
            this.email = "";
            this.successFlag = true;
          }
          this.load = false;
        })
        .catch((err) => {
          this.load = false;
          this.insuccessFlag = true;
          console.log(err);
        });
    },

    login() {
      if (this.$refs.form.validate()) {
        this.loginLoading = true;
        let payload = {
          credentials: {
            email: this.email,
            password: this.password,
          },
        };
        axios
          .post(`users/signin`, payload)
          .then(({ data, status }) => {
            if (status === 200) {
              if (data.client) {
                this.$store.commit("setClient", data);
              }
              if (data.service) {
                this.$store.commit("setService", data);
              }
            }
          })
          .catch((err) => {
            try {
              if (err.response.data.not_confirmed) {
                this.emailConfirmationFlag = true;
              } else {
                this.error = true;
              }
            } catch {
              this.error = true;
            }
          })
          .finally(() => {
            this.loginLoading = false;
          });
      }
    },
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || "Insira o seu email",
        (v) => /[\w]+@[\S]+\.[\w]+/.test(v) || "Insira um email válido",
      ];
    },
    passwordRules() {
      return [(v) => !!v || "Preencha com a sua palavra-chave"];
    },
  },
};
</script>

<style lang="scss" scoped>
.biip_close-icon-alert {
  margin: 0px 5px;
}
.biip_signup-link {
  text-decoration: none;
  color: white;
}
.biip_carousel-item-text {
  padding: 0px 10px 0px 10px;
  font-weight: normal;
  text-align: center;
}
.biip_alert-text {
  margin: 0px 23px 0px 5px;
  font-size: 14px;
}
.biip_text-col {
  margin-left: auto;
  margin-right: auto;
}
</style>
