<template>
  <div>
    <v-tooltip v-if="!participated" bottom color="white">
      <template v-slot:activator="{ on }">
        <v-img v-on="on" height="200px" contain :src="imageSrc"> </v-img>
      </template>
      <span style="color: #bb2649">{{ title }}</span>
    </v-tooltip>

    <v-tooltip v-else bottom color="white">
      <template v-slot:activator="{ on }">
        <v-img v-on="on" height="200px" contain :src="participatedImageSrc">
          <v-img
            v-on="on"
            class="certified-icon"
            height="50px"
            src="../assets/award.png"
            style="filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5))"
          ></v-img>
        </v-img>
      </template>
      <span style="color: #bb2649">{{ title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: String,
    participatedImageSrc: String,
    participated: Boolean,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
.certified-icon {
  position: absolute;
  height: 50px;
  width: 50px;
  top: 22px;
  left: auto;
  right: 16px;
  z-index: 2;
}
</style>