<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card class="biip__card--primary" outlined>
        <v-img width="90vw" max-height="90vh" contain :src="image">
          <v-icon class="icon" @click="$emit('close')">mdi-close</v-icon>
        </v-img>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__card {
    &--primary {
      background-color: #bb2649;
    }
  }
}
.icon {
  position: absolute;
  right: 0;
  margin: 8px;
  border-radius: 50%;
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>