import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#bb2649",
        secondary: "#FEFFFE",
        lowContrast: "#E5E4F1",
        error: "#ffacac"
      },
    },
  },
});
