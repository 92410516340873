<template>
  <v-window
    touchless
    v-model="$store.getters.getPage"
    style="padding-top: 56px"
  >
    <v-window-item style="height: 100%">
      <v-window v-model="window" vertical style="height: 100%">
        <v-window-item style="height: 100%">
          <explanation-section
            style="padding-top: 16px"
            @subscribe="window = 1"
          ></explanation-section>
        </v-window-item>

        <v-window-item
          class="biip__scroll"
          style="height: 100%"
        >
          <signup-section
            @explanation="window = 0"
          ></signup-section>
        </v-window-item>
      </v-window>
    </v-window-item>

    <v-window-item style="height: 95vh">
      <login-component
        style="padding-top: 16px"
        v-if="!$store.getters.userIsLogged"
        @toSignup="toSignup"
      ></login-component>
      <participant-dashboard
        style="padding-top: 16px;"
        v-else
      ></participant-dashboard>
    </v-window-item>

    <v-window-item>
      <v-sheet
        style="margin: auto"
        max-width="500px"
        class="text-center pa-4"
        color="transparent"
        dark
      >
        <h1
          class="mt-4 mb-0"
          style="filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.5))"
        >
          Como funciona
        </h1>
        <v-img
          style="filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.5))"
          height="200px"
          contain
          src="../assets/comofunciona.png"
        ></v-img>

        <p class="text-justify">
          <span style="font-size: 28px"><b>1. </b></span> Inscreve-te no evento
          e faz upload do teu CV.
        </p>

        <p class="text-justify">
          <span style="font-size: 28px"><b>2. </b></span>
          Usa a credencial para fazeres o check-in no evento e nas palestras.
        </p>

        <p class="text-justify">
          <span style="font-size: 28px"><b>3. </b></span>
          Participa nas palestras e interage com as empresas através desta
          webapp.
        </p>

        <v-btn text @click="$store.commit('changePage', 0)"
          >voltar <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-sheet>
    </v-window-item>
  </v-window>
</template>

<script>
import ExplanationSection from "../components/ExplanationSection.vue";
import SignupSection from "../components/SignupSection.vue";
import LoginComponent from "../components/Login.vue";
import ParticipantDashboard from "../components/ParticipantDashboard.vue";

export default {
  name: "HomeView",
  components: {
    ExplanationSection,
    SignupSection,
    LoginComponent,
    ParticipantDashboard,
  },

  data: () => ({
    window: 0,
  }),

  methods: {
    toSignup() {
      this.$store.commit("changePage", 0);
      this.window = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }
}
</style>


