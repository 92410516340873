var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:"biip__col"},[_vm._v(" Dados partilhados "),_c('v-btn',{attrs:{"icon":"","small":"","loading":_vm.refreshLoading},on:{"click":_vm.getSharedData}},[_c('v-icon',[_vm._v("mdi-autorenew")])],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"biip__table",attrs:{"headers":_vm.headers,"items":_vm.sharedByMeList,"height":"290px"},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.infosSharingToString(item.infos_sharing))+" ")]}},{key:"item.shares",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.infos_sharing.length)+" ")]}},{key:"item.views",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalViews(item.infos_sharing))+" ")]}},{key:"item.duplications",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalDuplications(item.infos_sharing))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"value":_vm.selectedShare == item,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.showItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus ")])]}}],null,true)},[(_vm.selectedShare && item.id == _vm.selectedShare.id)?_c('InfoSharedDetailsCard',{attrs:{"infos":_vm.selectedShare.infos_sharing},on:{"deleteInfo":_vm.deleteInfo,"close":function($event){_vm.selectedShare = null}}}):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }