<template>
  <v-card
    style="padding: 16px 8px; margin: 8px"
    dark
    flat
    color="rgba(187, 38, 73, 0.6)"
  >
    <v-form v-if="!errorFlag" ref="form" v-model="form" lazy-validation>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="name"
            v-mask="nameMask"
            :rules="nameRules"
            label="Nome completo"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="instituicao"
            :items="listaInstituicoes"
            item-text="name"
            item-value="id"
            dense
            label="Instituição de Ensino Superior"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="instituicaoRules"
          >
          </v-select>

          <v-expand-transition>
            <v-text-field
              v-model="outraNome"
              v-if="instituicao == 'other'"
              dense
              label="Nome da Instituição de Ensino Superior"
              outlined
              color="primary"
              :menu-props="{ offsetY: true }"
              :rules="instituicaoRules"
            >
            </v-text-field>
          </v-expand-transition>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="grau"
            :items="graus"
            item-text="name"
            item-value="id"
            dense
            label="Grau académico"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="grauRules"
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-if="instituicao == 'isep'"
            v-model="curso"
            :disabled="!grau"
            :items="listaCursosDisponíveis"
            dense
            label="Curso"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="cursoRules"
          >
          </v-select>

          <v-text-field
            v-else
            v-model="curso"
            :disabled="!instituicao"
            label="Curso"
            dense
            outlined
            color="primary"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="anoCurricular"
            :items="anoCurricularDisponivel"
            :disabled="!instituicao"
            dense
            label="Ano curricular"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="anoCurricularRules"
          >
          </v-select>
        </v-col>

        <v-col cols="12" md="6">
          <v-text-field
            v-model="numeroMecanografico"
            v-mask="'################'"
            :rules="mecanograficoRules"
            label="Número mecanográfico"
            :disabled="!instituicao"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-actions class="justify-center mb-4">
        <v-btn
          light
          :disabled="!form"
          rounded
          x-large
          color="white"
          depressed
          style="padding: 16px"
          :loading="loading"
          @click="subscribe"
          ><span style="color: #bb2649"
            >Prosseguir <v-icon class="mb-1">mdi-chevron-right</v-icon>
          </span></v-btn
        >
      </v-card-actions>
    </v-form>

    <div v-if="errorFlag">
      <lottie-player
        src="https://assets2.lottiefiles.com/packages/lf20_kxaldmkq.json"
        background="transparent"
        speed="2"
        style="
          width: 200px;
          height: 200px;
          margin-left: auto;
          margin-right: auto;
        "
        autoplay
      ></lottie-player>

      <v-card-text class="text-center">
        Ocorreu um erro ao atualizar a sua conta, verifique se os dados
        inseridos estão correctos.
        <p></p>
        Se o problema persistir por favor envie email para geral@unizon.pt
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn outlined @click="errorFlag = false">voltar</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
const SERVICE_ID = 2000000016;
const NAME_SIZE = 80;
const ENTRY_ID = 70;
const axios = require("axios").default;

export default {
  components: {},

  data: () => ({
    form: false,
    name: "",
    nameMask: Array.apply(null, Array(NAME_SIZE)).map(() => {
      return /[a-zA-ZçÇ\-ãÃoÕÁáÀàéÉíÍúÚ ]/;
    }),
    email: "",
    alreadyUsedEmailFlag: false,
    menu: null,
    date: "",
    password: "",
    showPass: false,
    instituicao: "",
    outraNome: "",
    listaInstituicoes: [
      { id: "isep", name: "Instituto Superior de Engenharia do Porto" },
      { id: "other", name: "Outra" },
    ],
    curso: "",
    licenciaturas: [
      "Biorrecursos",
      "Engenharia Biomédica",
      "Engenharia Civil",
      "Engenharia Eletrotécnica e Computadores",
      "Engenharia Eletrotécnica Sistemas Elétricos de Energia",
      "Engenharia Geotécnica e Geoambiente",
      "Engenharia e Gestão Industrial",
      "Engenharia Informática",
      "Engenharia Mecânica",
      "Engenharia Mecânica Automóvel",
      "Engenharia Química",
      "Engenharia de Sistemas",
      "Engenharia de Telecomunicações e Informática",
    ],
    mestrados: [
      "Biorrecursos",
      "Engenharia Biomédica",
      "Engenharia Civil",
      "Engenharia Eletrotécnica e Computadores",
      "Engenharia Eletrotécnia Sistemas Elétricos de Energia",
      "Engenharia Geotécnica e Geoambiente",
      "Engenharia e Gestão Industrial",
      "Engenharia Informática",
      "Engenharia de Inteligência Artificial",
      "Engenharia Mecânica",
      "Engenharia Química",
      "Engenharia de Sistemas",
      "Engenharia de Sistemas Computacionais Críticos",
    ],
    grau: "",
    graus: [
      { id: 1, name: "Licenciatura" },
      { id: 2, name: "Mestrado" },
      { id: 3, name: "Doutoramento" },
    ],
    numeroMecanografico: "",
    anoCurricular: "",
    loading: false,
    errorFlag: false,
  }),

  created() {
    this.uploadInfos();
  },

  methods: {
    logout() {
      axios
        .post(
          `users/logout`,
          {},
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.token,
            },
          }
        )
        .then()
        .catch((err) => {
          console.log(err);
        });
      this.$store.dispatch("LOGOUT");
    },

    uploadInfos() {
      this.grau = this.convertGrau(this.uploadGrau());
      this.name = this.uploadName();
      this.instituicao = this.convertInstituicao(this.uploadInstituicao());
      this.anoCurricular = this.uploadAno();
      this.curso = this.uploadCurso();
      this.numeroMecanografico = this.uploadNumero();
    },

    convertGrau(grauText) {
      let aux = this.graus.find((el) => el.name == grauText);
      return aux ? aux.id : grauText;
    },

    convertInstituicao(instText) {
      if (instText == "Instituto Superior de Engenharia do Porto") {
        return "isep";
      } else {
        this.outraNome = instText;
        return "other";
      }
    },

    uploadGrau() {
      let grau = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Grau Académico"
      );
      if (grau) {
        return grau.info.value;
      }

      grau = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Grau académico"
      );

      if (grau) {
        return grau.info.value;
      }
      return "";
    },

    uploadName() {
      let name = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "name"
      ).info.value;

      return name ? name : "";
    },

    uploadInstituicao() {
      let inst = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Instituição de Ensino Superior"
      ).info.value;

      return inst ? inst : "";
    },

    uploadAno() {
      let ano = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Ano curricular"
      ).info.value;

      return ano ? ano : "";
    },

    uploadCurso() {
      let curso = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Curso"
      ).info.value;

      return curso ? curso : "";
    },

    uploadNumero() {
      let numero = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Número mecanográfico"
      ).info.value;

      return numero ? numero : "";
    },

    subscribe() {
      if (this.$refs.form.validate()) {
        this.errorFlag = false;
        this.loading = true;

        let auxName = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "name"
        );
        let auxInst = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "other:Instituição de Ensino Superior"
        );
        let auxGrau = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "other:Grau Académico"
        );
        let auxCurso = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "other:Curso"
        );
        let auxAno = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "other:Ano curricular"
        );
        let auxNum = this.$store.getters.getUserInfos.find(
          (el) => el.info.name == "other:Número mecanográfico"
        );

        let payload = {
          buy_entry_params: {
            service_id: SERVICE_ID,
            entry_id: ENTRY_ID,
            payment_params: {
              option: "mb",
              phone_number: "",
            },
            infos: [
              {
                id: auxName ? auxName.info.id : "",
                name: "name",
                value: this.name,
              },
              {
                id: auxInst ? auxInst.info.id : "",
                name: "other:Instituição de Ensino Superior",
                value:
                  this.instituicao == "isep"
                    ? "Instituto Superior de Engenharia do Porto"
                    : this.outraNome,
              },
              {
                id: auxGrau ? auxGrau.info.id : "",
                name: "other:Grau Académico",
                value: this.graus.find((el) => el.id == this.grau).name,
              },
              {
                id: auxCurso ? auxCurso.info.id : "",
                name: "other:Curso",
                value: this.curso,
              },
              {
                id: auxAno ? auxAno.info.id : "",
                name: "other:Ano curricular",
                value: this.anoCurricular,
              },
              {
                id: auxNum ? auxNum.info.id : "",
                name: "other:Número mecanográfico",
                value: this.numeroMecanografico,
              },
            ],
            docs: [],
          },
        };

        axios
          .post(`/events/buy-entry/`, payload, {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.token,
            },
          })
          .then(({ status }) => {
            if (status == 201) {
              this.$emit("accountUpdated");
            }
          })
          .catch((err) => {
            this.errorFlag = true;
            console.log(err);
            this.loading = false;
          });
      }
    },
  },

  computed: {
    listaCursosDisponíveis() {
      switch (this.grau) {
        case 1:
          return this.licenciaturas;
        case 2:
          return this.mestrados;
        case 3:
          return this.mestrados;
        default:
          return [];
      }
    },

    anoCurricularDisponivel() {
      switch (this.grau) {
        case 1:
          return ["1º ano", "2º ano", "3º ano", "Finalizado"];
        case 2:
          return ["1º ano", "2º ano", "Finalizado"];
        case 3:
          return ["Não finalizado", "Finalizado"];
        default:
          return [];
      }
    },

    nameRules() {
      return [(v) => !!v || "Insira o seu nome"];
    },

    emailRules() {
      return [
        (v) => !!v || "Insira o seu email",
        (v) => /[\w]+@[\S]+\.[\w]+/.test(v) || "O email inserido é inválido",
      ];
    },

    dateRules() {
      return [
        (v) => !!v || "Insira a sua data de nascimento",
        (v) =>
          (new Date() -
            new Date(
              v.split("-")[0],
              v.split("-")[1] - 1,
              v.split("-")[2],
              0,
              0,
              0,
              0
            )) /
            (24 * 3600 * 1000 * 365.25) >
            14 || "É muito novo para participar neste evento",
      ];
    },

    passwordRules() {
      return [
        (v) => !!v || "Insira uma palavra-chave",
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(v) ||
          "A sua palavra-chave deve conter pelo menos 8 caracteres, 1 letra minúscula, 1 letra maiúscula e 1 número",
      ];
    },

    instituicaoRules() {
      return [(v) => !!v || "Selecione a sua Instituição de Ensino Superior"];
    },

    grauRules() {
      return [(v) => !!v || "Selecione o seu grau académico"];
    },

    cursoRules() {
      return [(v) => !!v || "Selecione o seu curso"];
    },

    anoCurricularRules() {
      return [(v) => !!v || "Selecione o seu ano curricular"];
    },

    mecanograficoRules() {
      return [(v) => !!v || "Insira o seu número mecanográfico"];
    },
  },
};
</script>