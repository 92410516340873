<template>
  <v-card :ripple="false" elevation="0" class="rotate-card mt-4 scroll">
    <v-card
      class="front pa-2 d-flex flex-column align-center justify-center"
      color="rgba(187, 38, 73, 0.6)"
      :class="{ flipped: flipped }"
      style="self-align: center"
    >
      <p style="font-size: 28px">
        Participa na palestra/workshop com a tua questão.
      </p>
      <v-card-actions>
        <v-btn
          v-if="$store.getters.getCanAsk"
          color="primary"
          large
          @click="flipCard"
          >Colocar questão</v-btn
        >
        <v-card-text v-else
          >Tens de efetuar o check-in na palestra, junto da
          organização.</v-card-text
        >
      </v-card-actions>
    </v-card>
    <v-card
      rounded="lg"
      light
      color="rgba(187, 38, 73, 0.6)"
      class="back"
      :class="{ flipped: flipped }"
    >
      <!-- Conteúdo de trás do card -->
      <iframe
        style="border-radius: 8px; backface-visibility: hidden;"
        src="https://app.sli.do/event/sHhD3KH4kEYqKQRzRYitET/embed/polls/84096314-c655-4372-a2a5-22b0b7509601"
        width="100%"
        height="350px"
      ></iframe>
    </v-card>
  </v-card>
</template>

<script>
import ActivityTable from "./ActivityTable.vue";
import SharedInfosTable from "./SharedInfosTable.vue";

export default {
  components: {
    ActivityTable,
    SharedInfosTable,
  },
  props: {
    eventId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      flipped: false,
    };
  },
  methods: {
    flipCard() {
      this.flipped = !this.flipped;
    },
  },
};
</script>

<style scoped>
.rotate-card {
  perspective: 1500px;
  height: 350px;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 8px;

}

.front {
  z-index: 2;
  transform: rotateY(0);
}

.back {
  transform: rotateY(-180deg);
}

.front.flipped {
  transform: rotateY(180deg);
}

.back.flipped {
  transform: rotateY(0);
}
</style>
