<template>
  <v-card
    min-height="90vh"
    style="padding: 16px 8px; margin: 8px"
    dark
    flat
    color="rgba(187, 38, 73, 0.6)"
  >
    <v-card-title class="justify-center">
      Inscrição
      <v-btn
        icon
        @click="
          errorFlag = false;
          successFlag = false;
          $emit('explanation');
        "
        ><v-icon large>mdi-chevron-down</v-icon></v-btn
      >
    </v-card-title>

    <v-card-subtitle class="text-center" style="color: white"
      >Se já participou na edição do ano passado, não precisa de se inscrever.
      Basta fazer login e atualizar os dados caso seja
      necessário.</v-card-subtitle
    >

    <v-form
      v-if="!successFlag && !errorFlag"
      ref="form"
      v-model="form"
      lazy-validation
    >
      <v-row dense>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="name"
            v-mask="nameMask"
            :rules="nameRules"
            label="Nome completo"
            required
            outlined
            dense
          >
          </v-text-field>

          <v-text-field
            v-model="email"
            label="Email"
            :rules="emailRules"
            :error-messages="
              alreadyUsedEmailFlag
                ? ['O email já foi associado a uma conta, faça login']
                : []
            "
            required
            outlined
            dense
            @change="verifyEmail"
          ></v-text-field>

          <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Palavra-chave"
            :type="showPass ? 'text' : 'password'"
            required
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            dense
            outlined
            @click:append="showPass = !showPass"
          ></v-text-field>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Data de nascimento"
                :rules="dateRules"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                required
              >
              </v-text-field>
            </template>

            <v-date-picker
              v-model="date"
              no-title
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
              locale="pt-pt"
            >
              <v-spacer></v-spacer>
              <v-btn text small color="primary" @click="menu = false"
                >Cancelar</v-btn
              >
              <v-btn
                outlined
                color="primary"
                @click="
                  $refs.menu.save(date);
                  $emit('input', date);
                "
                >Ok</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="12" md="6">
          <v-select
            v-model="instituicao"
            :items="listaInstituicoes"
            item-text="name"
            item-value="id"
            dense
            label="Instituição de Ensino Superior"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="instituicaoRules"
          >
          </v-select>

          <v-expand-transition>
            <v-text-field
              v-model="outraNome"
              v-if="instituicao == 'other'"
              dense
              label="Nome da Instituição de Ensino Superior"
              outlined
              color="primary"
              :menu-props="{ offsetY: true }"
              :rules="instituicaoRules"
            >
            </v-text-field>
          </v-expand-transition>

          <v-select
            v-model="grau"
            :items="graus"
            item-text="name"
            item-value="id"
            dense
            label="Grau académico"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="grauRules"
          >
          </v-select>

          <v-select
            v-if="instituicao == 'isep'"
            v-model="curso"
            :disabled="!grau"
            :items="listaCursosDisponíveis"
            dense
            label="Curso"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="cursoRules"
          >
          </v-select>

          <v-text-field
            v-else
            v-model="curso"
            :disabled="!instituicao"
            label="Curso"
            dense
            outlined
            color="primary"
          ></v-text-field>

          <v-select
            v-model="anoCurricular"
            :items="anoCurricularDisponivel"
            :disabled="!instituicao"
            dense
            label="Ano curricular"
            outlined
            color="primary"
            :menu-props="{ offsetY: true }"
            :rules="anoCurricularRules"
          >
          </v-select>

          <v-text-field
            v-model="numeroMecanografico"
            v-mask="'################'"
            :rules="mecanograficoRules"
            label="Número mecanográfico"
            :disabled="!instituicao"
            required
            outlined
            dense
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-card-actions class="justify-center mb-4">
        <v-btn
          light
          :disabled="!form"
          rounded
          x-large
          color="white"
          depressed
          style="padding: 16px"
          :loading="loading"
          @click="subscribe"
          ><span style="color: #bb2649">Inscrever</span></v-btn
        >
      </v-card-actions>
    </v-form>

    <div v-if="successFlag">
      <lottie-player
        src="https://lottie.host/715e8e13-209b-4346-adf3-3b0c55125d9c/6dvvSw6WyA.json"
        background="transparent"
        speed="1"
        style="
          width: 200px;
          height: 200px;
          margin-left: auto;
          margin-right: auto;
        "
        autoplay
      ></lottie-player>

      <v-card-text class="text-center">
        A sua inscrição foi realizada com sucesso.
        <p></p>
        <b>
          Consulte a sua caixa de correio eletrónico para confirmar o seu email.
        </b>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          outlined
          @click="
            successFlag = false;
            $emit('explanation');
          "
          >voltar</v-btn
        >
      </v-card-actions>
    </div>

    <div v-if="errorFlag">
      <lottie-player
        src="https://assets2.lottiefiles.com/packages/lf20_kxaldmkq.json"
        background="transparent"
        speed="2"
        style="
          width: 200px;
          height: 200px;
          margin-left: auto;
          margin-right: auto;
        "
        autoplay
      ></lottie-player>

      <v-card-text class="text-center">
        Ocorreu um erro ao criar a sua conta, verifique se os dados inseridos
        estão correctos ou se já tem conta criada.
        <p></p>
        Se o problema persistir por favor envie email para geral@unizon.pt
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn outlined @click="errorFlag = false">voltar</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
const NAME_SIZE = 80;
const ENTRY_ID = 70;
const axios = require("axios").default;

export default {
  components: {},

  data: () => ({
    form: false,
    name: "",
    nameMask: Array.apply(null, Array(NAME_SIZE)).map(() => {
      return /[a-zA-ZçÇ\-ãÃoÕÁáÀàéÉíÍúÚ ]/;
    }),
    email: "",
    alreadyUsedEmailFlag: false,
    menu: null,
    date: "",
    password: "",
    showPass: false,
    instituicao: "",
    outraNome: "",
    listaInstituicoes: [
      { id: "isep", name: "Instituto Superior de Engenharia do Porto" },
      { id: "other", name: "Outra" },
    ],
    curso: "",
    listaCursos: [
      "Biorrecursos",
      "Engenharia Biomédica",
      "Engenharia Civil",
      "Engenharia Eletrotécnica e Computadores",
      "Engenharia Eletrotécnica Sistemas Elétricos de Energia",
      "Engenharia Geotécnica e Geoambiente",
      "Engenharia e Gestão Industrial",
      "Engenharia Informática",
      "Engenharia Mecânica",
      "Engenharia Mecânica Automóvel",
      "Engenharia Química",
    ],
    licenciaturas: [
      "Biorrecursos",
      "Engenharia Biomédica",
      "Engenharia Civil",
      "Engenharia Eletrotécnica e Computadores",
      "Engenharia Eletrotécnica Sistemas Elétricos de Energia",
      "Engenharia Geotécnica e Geoambiente",
      "Engenharia e Gestão Industrial",
      "Engenharia Informática",
      "Engenharia Mecânica",
      "Engenharia Mecânica Automóvel",
      "Engenharia Química",
      "Engenharia de Sistemas",
      "Engenharia de Telecomunicações e Informática",
    ],
    mestrados: [
      "Biorrecursos",
      "Engenharia Biomédica",
      "Engenharia Civil",
      "Engenharia Eletrotécnica e Computadores",
      "Engenharia Eletrotécnia Sistemas Elétricos de Energia",
      "Engenharia Geotécnica e Geoambiente",
      "Engenharia e Gestão Industrial",
      "Engenharia Informática",
      "Engenharia de Inteligência Artificial",
      "Engenharia Mecânica",
      "Engenharia Química",
      "Engenharia de Sistemas",
      "Engenharia de Sistemas Computacionais Críticos",
    ],
    grau: "",
    graus: [
      { id: 1, name: "Licenciatura" },
      { id: 2, name: "Mestrado" },
      { id: 3, name: "Doutoramento" },
    ],
    numeroMecanografico: "",
    anoCurricular: "",
    loading: false,
    successFlag: false,
    errorFlag: false,
  }),

  methods: {
    verifyEmail(email) {
      axios
        .get(`/users/verify-email/${email}`)
        .then(({ status }) => {
          if (status == 200) {
            this.alreadyUsedEmailFlag = false;
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.alreadyUsedEmailFlag = true;
          }
        });
    },

    subscribe() {
      if (this.$refs.form.validate()) {
        this.successFlag = false;
        this.errorFlag = false;
        this.loading = true;
        let payload = {
          new_client_params: {
            service_id: 2000000000,
            entry_id: ENTRY_ID,
            client: {
              name: this.name,
              email: this.email,
              birthday: this.date,
              password_hash: this.password,
            },
            infos: [
              {
                name: "other:Instituição de Ensino Superior",
                value:
                  this.instituicao == "isep"
                    ? "Instituto Superior de Engenharia do Porto"
                    : this.outraNome,
              },
              {
                name: "other:Curso",
                value: this.curso,
              },
              {
                name: "other:Ano curricular",
                value: this.anoCurricular,
              },
              {
                name: "other:Número mecanográfico",
                value: this.numeroMecanografico,
              },
              {
                name: "other:Grau Académico",
                value: this.graus.find((el) => el.id == this.grau).name,
              },
            ],
            docs: [],
          },
        };

        axios
          .post(`/events/create-and-buy/`, payload)
          .then(({ status }) => {
            if (status == 201) {
              this.successFlag = true;
              this.$refs.form.reset();
            }
          })
          .catch((err) => {
            this.errorFlag = true;
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },

  computed: {
    listaCursosDisponíveis() {
      switch (this.grau) {
        case 1:
          return this.licenciaturas;
        case 2:
          return this.mestrados;
        case 3:
          return this.mestrados;
        default:
          return [];
      }
    },

    anoCurricularDisponivel() {
      switch (this.grau) {
        case 1:
          return ["1º ano", "2º ano", "3º ano", "Finalizado"];
        case 2:
          return ["1º ano", "2º ano", "Finalizado"];
        case 3:
          return ["Não finalizado", "Finalizado"];
        default:
          return [];
      }
    },

    nameRules() {
      return [(v) => !!v || "Insira o seu nome"];
    },

    emailRules() {
      return [
        (v) => !!v || "Insira o seu email",
        (v) => /[\w]+@[\S]+\.[\w]+/.test(v) || "O email inserido é inválido",
      ];
    },

    dateRules() {
      return [
        (v) => !!v || "Insira a sua data de nascimento",
        (v) =>
          (new Date() -
            new Date(
              v.split("-")[0],
              v.split("-")[1] - 1,
              v.split("-")[2],
              0,
              0,
              0,
              0
            )) /
            (24 * 3600 * 1000 * 365.25) >
            14 || "É muito novo para participar neste evento",
      ];
    },

    passwordRules() {
      return [
        (v) => !!v || "Insira uma palavra-chave",
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(v) ||
          "A sua palavra-chave deve conter pelo menos 8 caracteres, 1 letra minúscula, 1 letra maiúscula e 1 número",
      ];
    },

    instituicaoRules() {
      return [(v) => !!v || "Selecione a sua Instituição de Ensino Superior"];
    },

    grauRules() {
      return [(v) => !!v || "Selecione o seu grau académico"];
    },

    cursoRules() {
      return [(v) => !!v || "Selecione o seu curso"];
    },

    anoCurricularRules() {
      return [(v) => !!v || "Selecione o seu ano curricular"];
    },

    mecanograficoRules() {
      return [(v) => !!v || "Insira o seu número mecanográfico"];
    },
  },
};
</script>