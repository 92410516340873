<template>
  <div v-if="!loading">
    <v-sheet
      v-if="!$store.getters.isNew"
      dark
      class="text-center px-md-8 px-2"
      color="transparent"
    >
      <v-row class="mt-1">
        <v-col>
          <v-img
            style="filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.5))"
            height="50px"
            contain
            src="../assets/passbook-title.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" cols="12">
          <v-card
            light
            max-width="400px"
            rounded="lg"
            color="secondary"
            class="ma-4 mx-auto py-2"
          >
            <upload-client-image></upload-client-image>

            <v-card-text
              v-if="$store.getters.getUserInfos"
              class="text-center"
              style="font-size: 16px"
            >
              <b style="color: black">{{
                $store.getters.getUserInfos.find((el) => el.info.name == "name")
                  .info.value
              }}</b>
              <br />

              {{
                $store.getters.getUserInfos.find(
                  (el) => el.info.name == "other:Instituição de Ensino Superior"
                ).info.value
              }}
              <br />

              {{ grauAcademico }}
              ({{
                $store.getters.getUserInfos.find(
                  (el) => el.info.name == "other:Ano curricular"
                ).info.value
              }})

              <br />
              {{
                $store.getters.getUserInfos.find(
                  (el) => el.info.name == "other:Curso"
                ).info.value
              }}
            </v-card-text>

            <vue-qrcode
              :width="250"
              :quality="0.99"
              :margin="2"
              :value="$store.state.user.user.qr_token"
            />

            <br />

            <br />

            <a
              v-if="!loadingCV && cv"
              style="font-size: 20px; color: green"
              @click="showCV = true"
              >Currículo submetido
              <v-icon class="mb-1" color="success">mdi-check-circle</v-icon>
            </a>

            <span v-if="!loadingCV && !cv" style="font-size: 20px; color: red"
              >Currículo não submetido
              <v-icon class="mb-1" color="error">mdi-close-circle</v-icon>
            </span>

            <span v-if="loadingCV" style="font-size: 16px"
              >A carregar o seu cv...</span
            >

            <br />

            <input
              ref="FileInput"
              type="file"
              style="display: none"
              accept=".jpeg, .jpg, .png, .pdf"
              @change="fileChange"
            />

            <a
              v-if="!loadingCV && cv"
              style="color: grey; text-decoration: underline"
              @click="uploadCV"
              >alterar</a
            >
            <a
              v-if="!loadingCV && !cv"
              style="color: grey; text-decoration: underline"
              @click="
                uploadCV();
                isNewCV = true;
              "
              >submeter</a
            >
            <br />
            <v-btn
              class="mt-5 mb-2"
              x-large
              color="primary"
              @click="shareInfoFlag = true"
              >Partilhar dados</v-btn
            >
          </v-card>
        </v-col>

        <v-col md="8" cols="12">
          <PollCard />

          <Passbook :eventId="eventId" />

          <RotateCard :eventId="eventId" />
        </v-col>
      </v-row>

      <v-overlay v-model="shareInfoFlag">
        <share-info
          :eventId="eventId"
          @close="shareInfoFlag = false"
        ></share-info>
      </v-overlay>

      <v-overlay v-model="showCV">
        <web-viewer
          v-if="isPdfFile"
          :base64="cv.source"
          @close="showCV = false"
        ></web-viewer>

        <image-zoom-card
          v-else
          :image="cv.source"
          @close="showCV = false"
        ></image-zoom-card>
      </v-overlay>

      <v-overlay v-if="newCVBase64 && !isNewCV">
        <v-card class="text-start" light>
          <v-card-title> Pretende alterar o currículo? </v-card-title>
          <v-card-subtitle
            >Esta alteração será visivel nas empresas que já partilhou o
            documento</v-card-subtitle
          >

          <v-card-actions class="justify-space-between">
            <v-btn
              text
              small
              @click="
                newCVBase64 = '';
                newCV = null;
                $refs.FileInput.value = '';
              "
              >voltar</v-btn
            >
            <v-btn
              :loading="updateLoading"
              color="primary"
              @click="
                updateCV();
                $refs.FileInput.value = '';
              "
              >Atualizar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-overlay>

      <v-overlay v-if="newCVBase64 && isNewCV">
        <v-card class="text-start" light>
          <v-card-title> Pretende carregar o seu currículo? </v-card-title>
          <v-card-subtitle
            >Depois de carregar o curriculo por partilhá-lo com as
            empresas</v-card-subtitle
          >

          <v-card-actions class="justify-space-between">
            <v-btn
              text
              small
              @click="
                newCVBase64 = '';
                newCV = null;
                $refs.FileInput.value = '';
              "
              >voltar</v-btn
            >
            <v-btn
              :loading="updateLoading"
              color="primary"
              @click="
                submitCV();
                $refs.FileInput.value = '';
              "
              >Carregar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-overlay>
    </v-sheet>
    <v-sheet v-else dark class="text-center px-md-8 px-2" color="transparent">
      <v-row class="mt-1">
        <v-col>
          <v-img
            style="filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.5))"
            height="50px"
            contain
            src="../assets/passbook-title.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3>
            Recuperamos os teus dados da edição anterior, verifica se os dados
            estão atualizados e preenchidos, depois clica em proseguir.
          </h3>

          <update-infos-card @accountUpdated="checkIsNew"></update-infos-card>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
  <div v-else>
    <v-sheet dark class="text-center px-md-8 px-2" color="transparent">
      <v-row class="mt-1">
        <v-col>
          <v-img
            style="filter: drop-shadow(0px 2px 4px rgba(255, 255, 255, 0.5))"
            height="50px"
            contain
            src="../assets/passbook-title.png"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p style="margin-top: 30vh">
            <v-progress-circular
              size="20"
              width="2"
              indeterminate
              color="secondary"
            ></v-progress-circular>
          </p>
          <p style="font-size: 20px">Estamos a carregar os teus dados...</p>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
import ShareInfo from "./ShareInfo.vue";
import WebViewer from "./WebViewer.vue";
import ImageZoomCard from "./ImageZoomCard.vue";
import UploadClientImage from "./UploadClientImage.vue";
import RotateCard from "./RotateCard.vue";
import Passbook from "./Passbook.vue";
import PollCard from "./PolllCard.vue";
import VueQrcode from "vue-qrcode";
import UpdateInfosCard from "./UpdateInfosCard.vue";

const EVENT_ID = 41;
const ENTRY_ID = 70;
const axios = require("axios").default;

export default {
  components: {
    VueQrcode,
    ShareInfo,
    WebViewer,
    ImageZoomCard,
    UploadClientImage,
    RotateCard,
    Passbook,
    PollCard,
    UpdateInfosCard,
  },

  data: () => ({
    shareInfoFlag: false,
    cv: "",
    loadingCV: false,
    showCV: false,
    newCV: null,
    newCVBase64: "",
    updateLoading: false,
    isNewCV: false,
    eventId: EVENT_ID,
    loading: true,
  }),

  created() {
    this.validToken();
    this.getStudentCV();
    this.checkIsNew();
  },

  methods: {
    checkIsNew() {
      this.getParticipantInfos();
      this.getParticipantDocuments();
      axios
        .get(`/associations/myassociations`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ data, status }) => {
          if (status == 200) {
            let aux = data.data.some((el) => {
              return (
                el.association.entry_id == ENTRY_ID &&
                el.association.state == "ok"
              );
            });
            this.$store.commit("setIsNew", !aux);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },

    validToken() {
      if (this.$store.getters.userIsLogged) {
        axios
          .get("/users/valid-token", {
            headers: {
              Authorization: "Bearer " + this.$store.state.user.token,
            },
          })
          .then(({ status }) => {
            if (status != 200) {
              this.$store.dispatch("LOGOUT");
            }
          })
          .catch(() => {
            this.$store.dispatch("LOGOUT");
          });
      } else {
        this.$store.dispatch("LOGOUT");
      }
    },

    getStudentCV() {
      this.loadingCV = true;
      axios
        .get(`/docs/by-name/cv`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.cv = data.data.document;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingCV = false;
        });
    },

    getParticipantInfos() {
      axios
        .get(`/client_info/myinfos`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            this.$store.commit("setUserInfo", data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getParticipantDocuments() {
      axios
        .get(`/docs/mydocs`, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ data, status }) => {
          if (status === 200) {
            this.$store.commit("setUserDocs", data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fileChange(event) {
      if (event == null) {
        this.newCVBase64 = "";
      } else {
        let file = event.target.files[0];
        if (file.size < 1e7) {
          this.createBase64Image(file);
        } else {
          console.log("O ficheiro tem de ter menos de 10 MB");
        }
      }
    },

    createBase64Image(event) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.saveSrcCode(e.target.result);
      };
      reader.readAsDataURL(event);
    },

    saveSrcCode(srcCode) {
      this.newCVBase64 = srcCode;
    },

    uploadCV() {
      this.$refs.FileInput.click();
    },

    updateCV() {
      this.updateLoading = true;
      var payload = {
        source: this.newCVBase64,
      };
      axios
        .put(`/docs/${this.cv.id}`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 200) {
            this.cv = data.data.document;
            this.newCVBase64 = "";
            this.newCV = "";
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },

    submitCV() {
      this.updateLoading = true;
      var payload = {
        type: "cv",
        source: this.newCVBase64,
      };
      axios
        .post(`/docs`, payload, {
          headers: {
            Authorization: "Bearer " + this.$store.state.user.token,
          },
        })
        .then(({ status, data }) => {
          if (status === 201) {
            this.cv = data.data.document;
            this.newCVBase64 = "";
            this.newCV = "";
            this.getParticipantDocuments();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.updateLoading = false;
        });
    },
  },

  computed: {
    isPdfFile() {
      if (!this.cv) {
        return false;
      }
      var arr = this.cv.source.split(",");
      return arr[0].match(/:(.*?);/)[1] === "application/pdf";
    },

    isPdfFileNew() {
      if (!this.newCVBase64) {
        return false;
      }
      var arr = this.newCVBase64.split(",");
      return arr[0].match(/:(.*?);/)[1] === "application/pdf";
    },

    grauAcademico() {
      let grau = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Grau Académico"
      );
      if (grau) {
        return grau.info.value;
      }

      grau = this.$store.getters.getUserInfos.find(
        (el) => el.info.name == "other:Grau académico"
      );

      if (grau) {
        return grau.info.value;
      }
      return "grau académico não definido";
    },
  },
};
</script>