<template>
  <div>
    <v-icon
      class="biip__icon-left"
      v-if="currentPage != 1"
      large
      @click="currentPage--"
      >mdi-chevron-left</v-icon
    >

    <v-icon
      class="biip__icon-right"
      v-if="currentPage < pageCount"
      large
      @click="currentPage++"
      >mdi-chevron-right</v-icon
    >

    <v-icon class="biip__icon" @click="$emit('close')">mdi-close</v-icon>

    <v-card
      color="primary"
      class="biip__pdf-card biip__scroll"
      outlined
      dark
      rounded
    >
      <pdf :page="currentPage" @num-pages="pageCount = $event" :src="base64">
      </pdf>
    </v-card>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "WebViewer",
  components: {
    pdf,
  },
  props: {
    base64: {
      type: String,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.biip {
  &__pdf-card {
    max-height: 90vh;
    width: 90vw;
  }

  &__scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__scroll::-webkit-scrollbar {
    display: none;
  }

  &__icon {
    position: absolute;
    right: 0;
    margin: 8px;
    border-radius: 50%;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }

  &__icon-left {
    position: absolute;
    z-index: 1;
    left: 5px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }

  &__icon-right {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
  }
}
</style>