<template>
  <v-card :ripple="false" elevation="0" class="rotate-card mt-4 scroll">
    <v-card
      class="front pa-0"
      color="rgba(187, 38, 73, 0.6)"
      :class="{ flipped: flipped }"
    >
      <shared-infos-table :eventId="eventId"></shared-infos-table>
      <v-btn block plain @click="flipCard"> ver atividade</v-btn>
    </v-card>
    <v-card
      rounded="lg"
      color="rgba(187, 38, 73, 0.6)"
      class="back"
      :class="{ flipped: flipped }"
    >
      <activity-table :eventId="eventId"></activity-table>
      <v-btn block plain @click="flipCard">ver dados partilhados</v-btn>
    </v-card>
  </v-card>
</template>

<script>
import ActivityTable from "./ActivityTable.vue";
import SharedInfosTable from "./SharedInfosTable.vue";

export default {
  components: {
    ActivityTable,
    SharedInfosTable,
  },
  props: {
    eventId: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      flipped: false,
    };
  },
  methods: {
    flipCard() {
      this.flipped = !this.flipped;
    },
  },
};
</script>

<style scoped>
.rotate-card {
  perspective: 1500px;
  height: 600px;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.front,
.back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  border-radius: 8px;
}

.front {
  z-index: 2;
  transform: rotateY(0);
}

.back {
  transform: rotateY(-180deg);
}

.front.flipped {
  transform: rotateY(180deg);
}

.back.flipped {
  transform: rotateY(0);
}
</style>
